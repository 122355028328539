.files-tab
  display: flex
  gap: 10px

  > div:nth-child(2)
    display: flex
    flex-direction: column
    gap: 10px
    flex: 1 1 0

  &__tree
    width: 30%
    padding-top: 0 !important

    > div:last-child
      max-height: 550px
      overflow-y: auto

  .file-details
    display: flex
    flex-direction: column
    gap: 10px

    h4
      font-weight: normal
      font-size: 24px
      color: var(--color-text-primary)

    p
      font-size: 18px
      color: var(--color-text-secondary)

  .load-data
    display: flex
    flex-direction: column
    align-items: center
    gap: 20px

    h5
      font-weight: normal
      font-size: 24px
      color: var(--color-text-primary)



    &__button-container
      display: flex
      gap: 10px
      width: 100%

      button
        flex: 1 1 0


.dropzone-container
  display: flex
  gap: 10px
  width: 100%
  flex-direction: column

  &__upload
    height: 150px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 15px
    border: 1px dotted var(--color-stroke-input)
    background-color: var(--color-bg-input)
    color: var(--color-text-input-label)
    padding: 16px
    text-align: center
    cursor: pointer

    &:hover
      background-color: var(--color-bg-primary-ghost)
      border-color: var(--color-stroke-input-hover)
      color: var(--color-text-input-value)

      svg
        fill: var(--color-stroke-input-hover)

    p
      margin: 0

    svg
      width: 48px
      height: 48px
      fill: var(--color-text-input-label)
      transition: .3s

    span
      color: var(--color-text-input-label)
      font-size: 18px

      span
        color: var(--color-text-accent)

  &__limitations-container
    width: 100%
    gap: 5px
    display: flex
    justify-content: end
    align-items: center
    border-bottom: 1px solid var(--color-stroke-secondary)
    padding-bottom: 10px

  &__files-list

    display: flex
    flex-direction: column
    gap: 5px
    max-height: 300px
    overflow-y: auto

    div
      display: flex
      gap: 10px
      align-items: center
      justify-content: space-between

      svg
        cursor: pointer
        fill: var(--color-text-secondary)

.focused
  background-color: var(--color-bg-primary-ghost)
  border-color: var(--color-stroke-input-hover)
  color: var(--color-text-input-value)

  svg
    fill: var(--color-stroke-input-hover)