@use "./../../../assets/style/params.sass" as *

.notification-container
  position: fixed
  display: flex
  flex-direction: column-reverse
  bottom: toRem(16)
  right: toRem(16)
  z-index: 10000

.notification-cmp
  max-width: toRem(450)
  min-width: toRem(100)
  max-height: toRem(200)
  overflow: hidden
  transition: max-height .3s ease-out

  &:not(:first-child)
    margin-bottom: toRem(8)

  &_shrink
    max-height: 0

  .content
    position: relative
    display: flex
    align-items: start
    justify-content: space-between
    gap: toRem(8)
    padding: toRem(16) toRem(8)
    background-color: var(--color-bg-primary)
    color: var(--color-text-primary)
    font-weight: normal
    border-left: toRem(5) solid white
    overflow: hidden
    z-index: 1000
    transition: transform .3s ease-out

    &__close-button
      background: transparent
      border: none
      cursor: pointer

      path
        fill: var(--color-text-secondary)

    &__icon
      display: flex
      width: toRem(24)

    &:not(:last-child)
      margin-bottom: toRem(8)

    &_warning
      border-color: var(--color-notification-warning)

      .content__icon
        path
          fill: var(--color-notification-warning)

    &_success
      border-color: var(--color-notification-success)

      .content__icon
        path
          fill: var(--color-notification-success)

    &_error
      border-color: var(--color-notification-error)

      .content__icon
        path
          fill: var(--color-notification-error)

    &_slide-out
      transform: translateX(150%)
      flex: 0

    &_slide-in
      animation: slideIn ease-in-out .3s

    @keyframes slideIn
      from
        transform: translateX(100%)

      to
        transform: translateX(0%)