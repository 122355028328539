@use "./../../../assets/style/params.sass" as *

.dropdown
  width: 100%
  position: relative

  &__input
    background-color: var(--color-bg-input)
    display: flex
    gap: toRem(5)
    position: relative
    justify-content: space-between
    align-items: center
    cursor: pointer
    padding: toRem(15) toRem(20)
    border-bottom: 1px solid var(--color-stroke-input)
    transition: .3s

    > span
      flex: 1 1 0
      color: var(--color-text-input-value)
      text-transform: uppercase
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap

    &:hover
      border-color: var(--color-stroke-input-hover)
      .arrow
        fill: var(--color-stroke-input-hover)

    &_empty
      color: var(--color-text-input-label)

    .arrow
      fill: var(--color-text-input-label)
      transition: .3s

      &_up
        transform: rotateX(180deg)

  &__items
    &_closed
      display: none
      flex-direction: column

    &_opened
      display: flex
      flex-direction: column
      position: absolute
      width: 100%
      background-color: var(--color-bg-dropdown)
      box-shadow: var(--box-shadow)
      max-height: toRem(300)
      overflow-y: auto

      .item
        display: flex
        gap: toRem(5)
        align-items: center
        border-radius: toRem(8)
        cursor: pointer
        transition: .3s
        padding: toRem(15)

        &:hover
          background-color: var(--color-bg-dropdown-item-hover)

        &_selected
          background-color: var(--color-bg-dropdown-item-hover)

        > span
          font-size: toRem(16)
          color: var(--color-text-input-value)
          text-transform: uppercase
          text-overflow: ellipsis
          overflow: hidden
          white-space: nowrap

    &_empty
      background-color: var(--color-bg-dropdown)
      color: var(--color-text-input-label)
      display: flex
      justify-content: center
      text-align: center
      padding: toRem(30) toRem(15)
      border-radius: toRem(12)