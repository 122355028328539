@use "./params.sass" as *
@use "themes/dark" as *
@use "themes/light" as *

*
  scrollbar-color: var(--color-bg-secondary) var(--color-bg-primary)
  scrollbar-width: thin
  transition: background-color .3s, color .3s, box-shadow .3s, border-color .3s

html
  background-color: var(--color-bg-secondary)
  color: var(--color-text-secondary)

input
  border: none
  background-color: transparent
  outline: none
  -webkit-appearance: none
  color: var(--color-text-primary)

button
  cursor: pointer

a
  text-decoration: none
