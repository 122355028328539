@use "./../../../assets/style/params.sass" as *

.badge-cmp
  font-size: toRem(16)
  padding: toRem(5)
  border-radius: toRem(5)
  display: flex
  align-items: center

  &_primary
    background-color: var(--color-bg-badge-primary)
    color: var(--color-text-badge-primary)

  &_default
    background-color: var(--color-bg-badge-secondary)
    color: var(--color-text-badge-secondary)

  &_ghost
    background-color: var(--color-bg-badge-ghost)
    color: var(--color-text-badge-ghost)

  &_error
    background-color: var(--color-error-bg)
    color: var(--color-error)