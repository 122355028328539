@use "./../../../assets/style/params.sass" as *

.personal-account-dropdown
  position: relative
  z-index: 1000

  &__panel
    display: flex
    gap: toRem(5)
    align-items: center
    cursor: pointer
    transition: 0.3s
    padding: toRem(5) toRem(10)
    border-radius: toRem(8)

    &:hover
      background-color: var(--color-bg-primary)

    span
      color: var(--color-text-primary)
      text-transform: uppercase

    svg
      fill: var(--color-text-secondary)
      transition: .3s

    &_open
      svg
        transform: rotateX(180deg)

  &__list
    width: max-content
    position: absolute
    right: 0
    z-index: 100

    background-color: var(--color-bg-primary)
    box-shadow: var(--box-shadow)
    border-radius: toRem(8)

    ul
      list-style: none
      border-radius: toRem(8)
      margin: 0
      padding: 0

      li
        display: flex
        align-items: center
        gap: toRem(10)
        color: var(--color-text-primary)
        padding: toRem(15) toRem(20)
        cursor: pointer
        transition: .3s
        border-radius: toRem(8)
        user-select: none

        &:hover
          background-color: var(--color-bg-secondary)

        .stroke-icon
          width: toRem(18)

          *
            stroke: var(--color-text-secondary)

        .fill-icon

          *
            fill: var(--color-text-secondary)