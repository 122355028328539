@use "../../../assets/style/params.sass" as *

.test-tab
  width: 100%
  display: flex
  gap: toRem(10)

  > div
    width: 100%
    display: flex
    flex-direction: column
    gap: toRem(20)

  &__tests-list
    display: flex
    flex-direction: column
    gap: toRem(5)

    > div
      display: flex
      align-items: center
      gap: toRem(10)

    > div:first-child
      border-bottom: 1px solid var(--color-text-secondary)
      padding-bottom: toRem(5)