@use "./../../../assets/style/params.sass" as *

.table-cmp
  border-collapse: collapse
  width: 100%
  box-shadow: var(--box-shadow)

  th
    color: var(--color-text-primary)
    background: var(--color-bg-primary)
    border-right: 1px solid var(--color-stroke-secondary)
    font-size: 18px
    padding: 18px 14px
    text-align: left
    vertical-align: middle

    &:last-child
      border-right: none

    .text-left
      text-align: left

    .text-center
      text-align: center

    .text-right
      text-align: right

  tr
    border-top: 1px solid var(--color-stroke-secondary)
    border-bottom: 1px solid var(--color-stroke-secondary)
    color: var(--color-text-secondary)
    font-size: 16px
    font-weight: normal

    &:hover
      td
        background: var(--color-bg-primary-ghost)
        color: var(--color-text-primary)

    &:first-child
      border-top: none

    &:last-child
      border-bottom: none

    &:nth-child(odd)
      td
        background: var(--color-bg-secondary)

      &:hover
        td
          background: var(--color-bg-primary-ghost)

  td
    overflow: hidden
    background: var(--color-bg-primary)
    padding: 8px 14px
    text-align: left
    font-size: 14px
    border-right: 1px solid var(--color-stroke-secondary)
    transition: .1s

    &:last-child
      border-right: 0

    .text-left
      text-align: left

    .text-center
      text-align: center

    .text-right
      text-align: right
