@use "./../../../assets/style/params.sass" as *

.hint-icon
  display: flex
  justify-content: center
  align-items: center

  height: toRem(12)
  width: toRem(12)

  padding: toRem(1)

  border-radius: 100%
  border: 1px var(--color-stroke-primary) solid

  cursor: pointer
  transition: .3s

  &:hover
    opacity: 0.7

  path
    fill: var(--color-stroke-primary)