@use "./../../../assets/style/params.sass" as *

.input-field
  width: 100%

  .input-cmp
    width: 100%
    position: relative
    display: flex
    align-items: center
    background-color: var(--color-bg-input)

    &__value
      width: 100%
      padding: toRem(18) toRem(8) toRem(8) toRem(8)
      border-bottom: 1px solid var(--color-stroke-input)
      color: var(--color-text-input-value)
      font-size: toRem(16)
      transition: border .2s, background-color .2s

      &:focus
        border-color: var(--color-stroke-input-hover)

        + label
          top: 8px
          color: var(--color-stroke-input-hover)
          font-size: toRem(12)

      &:hover
        border-color: var(--color-stroke-input-hover)

      &_error
        border-color: var(--color-error)

        + label
          color: var(--color-error)

      &_error:hover
        border-color: var(--color-error)

      &_error:focus
        border-color: var(--color-error)

        + label
          color: var(--color-error)

    &__label
      position: absolute
      top: 50%
      left: toRem(8)
      padding: 0 toRem(2)
      color: var(--color-text-input-label)
      border-radius: toRem(2)
      font-size: toRem(16)
      font-weight: normal
      pointer-events: none
      transform: translateY(-50%)
      transition: .2s

      &_fill
        font-size: toRem(12)
        top: 8px

    &__password-eye
      position: absolute
      right: toRem(3)
      padding: 0 toRem(4)
      //background-color: var(--color-bg-input)
      cursor: pointer
      transition: .3s

      path
        stroke: var(--color-stroke-input)
        transition: .3s

      &:hover
        path
          stroke: var(--color-text-input-label)

      &_show
        path
          stroke: var(--color-stroke-input-hover)

      &_show:hover
        path
          filter: brightness(0.9)

  .error-text
    margin-top: toRem(2)
    max-height: 0

    color: var(--color-error)
    font-size: toRem(12)

    overflow: hidden
    transition: max-height .2s

    &_show
      max-height: toRem(40)