@use "../../assets/style/params.sass" as *

.start-page
  display: flex
  justify-content: center
  align-items: center

  &__content
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: toRem(10)
    margin-top: 100px
    width: max-content

    background-color: var(--color-bg-primary)
    padding: toRem(55)
    border-radius: toRem(8)

    h1
      color: var(--color-text-primary)
      text-align: center

    button a
      width: 100%