@use "./../../../assets/style/params.sass" as *

.project-members
  display: flex
  gap: toRem(10)
  align-items: center

  > span
    color: var(--color-text-secondary)

  &__list
    display: flex
    align-items: center
    gap: toRem(5)

    > span
      color: var(--color-text-secondary)

    > div
      display: flex
      flex-wrap: wrap
      gap: toRem(5)

      > span
        height: 100%

  &__unit
    gap: toRem(5)

    &:hover
      svg
        display: block

    svg
      display: none
      fill: var(--color-text-secondary)
      cursor: pointer


  &__add-member-btn
    position: relative

    > span
      cursor: pointer

    > div
      width: max-content
      position: absolute
      display: flex
      flex-direction: column
      gap: toRem(10)
      right: 0
      left: 0
      top: toRem(40)
      z-index: 100

      background-color: var(--color-bg-primary)
      box-shadow: var(--box-shadow)
      border-radius: toRem(8)
      padding: toRem(16) toRem(16) 0 toRem(16)

      input
        border-bottom: 1px var(--color-text-secondary) solid

      ul
        list-style-type: none
        padding: 0
        margin: 0

        li
          display: flex
          justify-content: space-between
          align-items: center
          gap: toRem(5)

          > div
            display: flex
            flex-direction: column
            margin-bottom: toRem(5)

            span:first-child
              color: var(--color-text-primary)

            span:last-child
              color: var(--color-text-secondary)
              font-size: toRem(14)

          > span
            height: min-content
            cursor: pointer
