@use "../../assets/style/params.sass" as *

.project-page
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  color: var(--color-text-primary)
  gap: toRem(15)

  &__header
    width: 100%

    > div
      display: flex
      flex-direction: column
      justify-content: space-between
      gap: toRem(20)
      padding-bottom: 0 !important

    &__edit-project-name
      h6
        font-size: toRem(20)
        color: var(--color-text-primary)
        font-weight: normal
        text-transform: uppercase
        transition: .3s

      input
        font-size: toRem(20)
        color: var(--color-text-primary)
        text-transform: uppercase
        border-bottom: 1px var(--color-text-secondary) solid

  &__content
    width: 100%

.edit-project-name-cmp
  h6
    font-size: toRem(28)
    color: var(--color-text-primary)
    font-weight: normal
    text-transform: uppercase
    transition: .3s

  input
    font-size: toRem(28)
    color: var(--color-text-primary)
    text-transform: uppercase
    border-bottom: 1px var(--color-text-secondary) solid

