@use "./../../../assets/style/params.sass" as *

.tabs-cmp
  display: flex
  flex-wrap: wrap
  gap: toRem(5)
  padding: 0
  border: 0 solid var(--color-stroke-secondary)

  &_bottom
    border-bottom-width: 1px

    .tabs-cmp__item
      border-bottom-width: 2px

  &_top
    border-top-width: 1px

    .tabs-cmp__item
      border-top-width: 2px

  &__item
    border: 0 solid transparent
    padding: toRem(15) toRem(20)
    background-color: transparent
    cursor: pointer
    color: var(--color-text-primary)
    transition: .3s
    flex: 1 1 0
    text-align: center
    display: flex
    justify-content: center
    align-items: center

    &:hover
      border-color: var(--color-stroke-primary)
      background-color: var(--color-bg-primary-ghost)

    &_selected
      border-color: var(--color-stroke-primary)