@use "../../../../assets/style/params.sass" as *

.domain-data
  display: flex
  flex-direction: column
  gap: toRem(5)

  h5
    color: var(--color-text-primary)
    text-align: start
    font-size: toRem(24)
    margin: 0
    text-transform: uppercase

  span
    color: var(--color-text-secondary)
    margin-top: toRem(10)

  ul
    margin: 0
    padding: 0

    li
      margin-top: toRem(5)
      list-style: none
      cursor: pointer

      &:hover
        color: var(--color-text-accent)
