@use "./../../../assets/style/params.sass" as *

.layout-cmp
  min-height: 100vh
  background-color: var(--color-bg-secondary)
  //background: radial-gradient(circle at 74.2% 60.9%, rgb(0, 28, 28) 5.2%, rgb(23, 23, 23) 75.3%)

  .layout-header
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    height: 50px
    z-index: 100
    padding-top: 10px

    &__content
      display: flex
      justify-content: space-between
      align-items: center
      max-width: calc(1300px - 60px)
      width: 100%
      background-color: var(--color-bg-primary)
      padding: 5px 30px
      box-shadow: var(--box-shadow)

      > div:first-child
        display: flex
        gap: toRem(10)

        ul

          li
            display: inline

            a
              text-decoration: none
              color: var(--color-text-primary)
              transition: .3s

              &:hover
                color: var(--color-text-accent)

          li:not(:first-child)
            margin-left: toRem(20)

    &__logo
      display: flex
      align-items: center
      text-decoration: none

      h5
        font-weight: normal
        color: var(--color-text-primary)
        margin: 0
        font-size: toRem(24)
        letter-spacing: toRem(1)


      span
        color: var(--color-text-accent)
        font-size: toRem(24)

  .layout-content
    > div
      max-width: 1300px
      margin: 0 auto
      padding: toRem(20) toRem(10) toRem(50) toRem(10)