@use "./../../../assets/style/params.sass" as *

.tree-cmp

  ul
    margin: 0
    list-style-type: none
    padding: 0 0 0 toRem(40)
    border-left: 1px solid var(--color-stroke-secondary)

    > span
      color: var(--color-text-secondary)
      text-align: center

    .tree-item

      user-select: none

      &_selected
        > div
          background-color: var(--color-bg-primary-ghost)

          > span
            color: var(--color-text-accent)

      &_open
        > div
          svg
            transform: rotate(-90deg)

      > div
        display: flex
        align-items: center
        gap: toRem(5)
        padding: toRem(5)

        > span
          font-size: toRem(16)
          text-overflow: ellipsis
          overflow: hidden
          white-space: nowrap
          cursor: pointer

          &:hover
            color: var(--color-text-accent)

        svg
          fill: var(--color-text-secondary)
          cursor: pointer
          transition: .3s

          &:hover
            fill: var(--color-text-accent)

  ul:first-child
    border: none
    padding-left: 0

    > .tree-item
      margin-bottom: toRem(10)

      > div > span
        font-size: toRem(20)

