@use "./../../../../assets/style/params.sass" as *

.login-modal
  display: flex
  flex-direction: column
  gap: 15px

  h5
    margin: 0 0 20px 0
    color: var(--color-text-primary)
    text-align: center
    font-size: toRem(24)

  &__error
    color: var(--color-error)

  &__remember-me
    display: flex
    align-items: center
    gap: 5px
    color: var(--color-text-primary)

  button
    margin-top: 20px
    width: 100%

  &__forgot-password
    display: flex
    justify-content: space-between
    gap: toRem(10)

    span
      cursor: pointer
      font-size: toRem(14)
      transition: .3s

      &:hover
        color: var(--color-text-accent)