:root {
  --color-bg-primary: #1B1B1B;
  --color-bg-secondary: #272727;
  --color-bg-primary-ghost: rgba(14, 156, 162, 0.14);

  --color-text-primary: #FFFFFF;
  --color-text-secondary: #B3B3B3;
  --color-text-accent: #3FBAC2;

  --color-stroke-primary: #3FBAC2;
  --color-stroke-secondary: #3a3a3a;

  --box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4);

  --color-bg-button-primary: #3FBAC2;
  --color-bg-button-primary-hover: #33a7af;
  --color-text-button-primary: #ffffff;
  --color-bg-button-secondary: rgba(14, 156, 162, 0.14);
  --color-bg-button-secondary-hover: rgba(14, 156, 162, 0.2);
  --color-text-button-secondary: #3FBAC2;
  --color-bg-button-disabled: rgba(84, 84, 84, 0.4);
  --color-text-button-disabled: #FFFFFF;

  --color-bg-input: rgba(0, 0, 0, 0.1);
  --color-bg-dropdown: #1B1B1B;
  --color-bg-dropdown-item-hover: #272727;
  --color-text-input-label: #B3B3B3;
  --color-text-input-value: #FFFFFF;
  --color-stroke-input: #3a3a3a;
  --color-stroke-input-hover: #3FBAC2;

  --color-bg-badge-primary: rgba(14, 156, 162, 0.14);
  --color-text-badge-primary: #3FBAC2;
  --color-bg-badge-secondary: #424242;
  --color-text-badge-secondary: #DADADA;
  --color-bg-badge-ghost: rgba(107, 107, 107, 0.07);
  --color-text-badge-ghost: #DADADA;

  --color-bg-tooltip: rgba(0, 0, 0, 0.9);
  --color-text-tooltip: rgba(255, 255, 255, 0.8);

  --color-loader: #FFFFFF;

  --color-notification-warning: #f3c221;
  --color-notification-success: #4caf50;
  --color-notification-error: #d32e2e;

  --color-error: #d32e2e;
  --color-error-bg: rgba(211, 46, 46, 0.2);
}