[data-theme="light"] {
  --color-bg-primary: #ffffff;
  --color-bg-secondary: #f3f3f3;
  --color-bg-primary-ghost: rgba(14, 156, 162, 0.14);

  --color-text-primary: #000000;
  --color-text-secondary: #525252;
  --color-text-accent: #3FBAC2;

  --color-stroke-primary: #3FBAC2;
  --color-stroke-secondary: #b9b9b9;

  --box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);

  --color-bg-button-primary: #3FBAC2;
  --color-bg-button-primary-hover: #33a7af;
  --color-text-button-primary: #ffffff;
  --color-bg-button-secondary: rgba(14, 156, 162, 0.14);
  --color-bg-button-secondary-hover: rgba(14, 156, 162, 0.2);
  --color-text-button-secondary: #3FBAC2;
  --color-bg-button-disabled: rgba(189, 189, 189, 0.4);
  --color-text-button-disabled: #FFFFFF;

  --color-bg-input: rgba(194, 194, 194, 0.1);
  --color-bg-dropdown: #FFFFFF;
  --color-bg-dropdown-item-hover: #f3f3f3;
  --color-text-input-label: #525252;
  --color-text-input-value: #000000;
  --color-stroke-input: #b9b9b9;
  --color-stroke-input-hover: #3FBAC2;

  --color-bg-badge-primary: rgba(14, 156, 162, 0.14);
  --color-text-badge-primary: #3FBAC2;
  --color-bg-badge-secondary: #dedede;
  --color-text-badge-secondary: #DADADA;
  --color-bg-badge-ghost: #f3f3f3;
  --color-text-badge-ghost: #989898;

  --color-bg-tooltip: rgba(0, 0, 0, 0.9);
  --color-text-tooltip: rgba(255, 255, 255, 0.9);

  --color-loader: #525252;

  --color-notification-warning: #f3c221;
  --color-notification-success: #4caf50;
  --color-notification-error: #d32e2e;

  --color-error: #d32e2e;
  --color-error-bg: rgba(211, 46, 46, 0.2);
}
