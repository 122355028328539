@use "../../assets/style/params.sass" as *

.projects-page
  display: flex
  flex-direction: column
  gap: toRem(10)

  &__empty-list-text
    display: flex
    text-align: center
    justify-content: center
    align-items: center
    margin-top: toRem(40)

  &__header
    display: flex
    flex-direction: column
    justify-content: space-between
    gap: toRem(10)
    padding-bottom: 0 !important

    > div:first-child
      display: flex
      justify-content: space-between
      align-items: center
      gap: toRem(10)
      height: toRem(40)

      h2
        color: var(--color-text-primary)
        font-size: toRem(28)
        font-weight: normal
        text-transform: uppercase

  &__projects-list
    display: flex
    flex-direction: column
    gap: 10px

  .project-card
    display: flex
    justify-content: space-between
    align-items: start
    text-decoration: none

    &:hover

      > div > span
        color: var(--color-text-accent)

      .project-card__delete-button
        transition: .3s
        opacity: 1

    > div
      display: flex
      flex-direction: column
      gap: toRem(30)

      > span
        font-size: toRem(20)
        color: var(--color-text-primary)
        text-transform: uppercase
        transition: .3s

    &__delete-button
      transition: 0s
      opacity: 0
      fill: var(--color-text-secondary)

      &:hover
        filter: brightness(.5)


  &__create-button-container
    display: flex
    align-items: center
    gap: toRem(5)
    height: toRem(36)

    svg
      cursor: pointer

      path
        fill: var(--color-text-secondary)
        transition: .3s

      &:hover
        path
          fill: var(--color-text-accent)

  &__create-button
    width: max-content
    display: flex
    align-items: center
    gap: toRem(5)
    padding: toRem(10) toRem(32)
    justify-content: center
    border: 0
    background-color: var(--color-bg-button-secondary)
    border-radius: toRem(24)
    color:  var(--color-text-button-secondary)
    text-transform: uppercase
    transition: .3s

    &:hover
      background-color: var(--color-bg-button-secondary-hover)

    &_normal
      cursor: pointer

    &_edit
      border-color: var(--color-stroke-primary)

      input
        border-bottom: 1px solid var(--color-stroke-secondary)