@use "../../../assets/style/params.sass" as *

.data-tab
  width: 100%
  display: flex
  gap: toRem(10)

  &__tree
    padding-top: 0 !important
    width: 30%

    &_hidden
      display: none

    > div:last-child
      max-height: 550px
      overflow-y: auto

  &__info
    display: flex
    flex: 1 1 0
    flex-direction: column
    gap: toRem(10)

    h4
      font-weight: normal
      color: var(--color-text-secondary)

    > div
      display: flex
      flex: 1 1 0
      overflow-x: auto

      p
        font-size: toRem(18)
        color: var(--color-text-secondary)

    h5
      text-align: center
      font-weight: normal
      font-size: toRem(18)
      color: var(--color-text-secondary)
      margin: toRem(40) 0

.data-search-input
  display: flex
  justify-content: space-between
  align-items: center
  gap: toRem(10)
  margin: toRem(10) 0

  &__settings
    position: relative
    display: flex

    > svg
      width: toRem(40)
      height: toRem(40)
      cursor: pointer

      &:hover
        path, circle
          stroke: var(--color-stroke-primary)

      path, circle
        stroke: var(--color-text-secondary)
        transition: 0.3s

    > div
      width: max-content
      position: absolute
      display: flex
      flex-direction: column
      gap: toRem(10)
      right: 0
      left: 0
      top: toRem(40)
      z-index: 100

      background-color: var(--color-bg-primary)
      box-shadow: var(--box-shadow)
      border-radius: toRem(8)
      padding: toRem(16) toRem(16)

      > span
        display: flex
        gap: toRem(5)