@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap')

//@font-face
//  font-family: 'Play'
//  font-style: normal
//  font-weight: normal
//  src: url("../fonts/Play-Regular.ttf") format('tff')
//
//@font-face
//  font-family: 'Play'
//  font-style: normal
//  font-weight: bold
//  src: url("../fonts/Play-Bold.ttf") format('tff')


*
  font-family: "Play", sans-serif
  font-size: 14px
