@use "./../../../assets/style/params.sass" as *

.loader-cmp

  border: 5px solid var(--color-loader)
  border-bottom-color: transparent
  border-radius: 50%
  display: inline-block
  box-sizing: border-box
  animation: rotation 1s linear infinite

  &_large
    width: 48px
    height: 48px
    border-width: 5px

  &_small
    width: 24px
    height: 24px
    border-width: 2px


@keyframes rotation
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)