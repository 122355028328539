@use "./../../../assets/style/params.sass" as *

.checkbox
    display: flex
    align-items: center
    cursor: pointer

    span
        color: var(--color-text-primary)
        font-size: toRem(18)
        transition: color .2s

    &:hover
        .checkbox__frame
            border-color: var(--color-stroke-primary)

        svg path
            fill: var(--color-stroke-primary)

        span
            color: var(--color-stroke-primary)

    &_selected
        .checkbox__frame
            border-color: var(--color-stroke-primary)

        svg path
            fill: var(--color-stroke-primary)

    &__frame
        border: 1px solid var(--color-text-primary)
        display: flex
        align-items: center
        justify-content: center
        border-radius: toRem(4)
        transition: border .2s
        width: toRem(20)
        height: toRem(20)