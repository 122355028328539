@use "./../../../assets/style/params.sass" as *

.button
    border: 0
    border-radius: toRem(8)
    color: var(--color-text-primary)
    padding: toRem(13) toRem(48)
    font-size: toRem(18)
    text-align: center
    display: inline-block
    cursor: pointer
    transition-duration: .3s

    &_primary
        color: var(--color-text-button-primary)
        background-color: var(--color-bg-button-primary)

        &:hover
            background-color: var(--color-bg-button-primary-hover)

        &:active
            filter: brightness(.7)

    &_secondary
        color: var(--color-text-button-secondary)
        background-color: var(--color-bg-button-secondary)

        &:hover
            background-color: var(--color-bg-button-secondary-hover)

        &:active
            filter: brightness(.7)

    &_disabled
        cursor: auto
        background-color: var(--color-bg-button-disabled)
        color: var(--color-text-button-disabled)

        &:hover
            background-color: var(--color-bg-button-disabled)

        &:active
            filter: brightness(1)