@use "../../../../assets/style/params.sass" as *

.primary-data-table
  width: 100%
  max-height: 500px
  overflow-y: auto

  &__values-container
    display: flex
    flex-wrap: wrap
    gap: toRem(5)
    justify-content: center
    align-items: center

    > span
      margin: 0